function filterInspections() {
  const filterSchool = document.querySelector("#school_filter");
  const filterInspection = document.querySelector("#inspection_filter");
  const filterInspectionStatus = document.querySelector("#inspection_status_filter");
  const filterInspectionsTerm = document.querySelector("#inspection_status_term");
  const filterInspectionYear = document.querySelector("#inspection_status_year");

  if (filterSchool) {
    filterSchool.addEventListener("change", function (event) {
      let selectedSchool = event.target.value;
      let tableBody = document.querySelector("table tbody");
      axios
        .get("/admin/results/filter/", {
          params: {
            school: selectedSchool,
            inspection: filterInspection.value,
            status: filterInspectionStatus.value,
            term: filterInspectionStatus.value,
            year: filterInspectionYear.value,
          },
        })
        .then(function (response) {
          // Handle the response data here

          let filteredResults = response.data.results.data;
          let tableRows = renderResultsTable(filteredResults);

          tableBody.innerHTML = tableRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  if (filterInspection) {
    filterInspection.addEventListener("change", function (event) {
      let filterInspection = event.target.value;

      let tableBody = document.querySelector("table tbody");
      axios
        .get("/admin/results/filter/", {
          params: {
            school: filterSchool.value,
            inspection: filterInspection,
            status: filterInspectionStatus.value,
            term: filterInspectionsTerm.value,
            year: filterInspectionYear.value,
          },
        })
        .then(function (response) {
          // Handle the response data here

          let filteredResults = response.data.results.data;
          let tableRows = renderResultsTable(filteredResults);

          tableBody.innerHTML = tableRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  if (filterInspectionStatus) {
    filterInspectionStatus.addEventListener("change", function (event) {
      let filterInspectionStatus = event.target.value;

      let tableBody = document.querySelector("table tbody");
      axios
        .get("/admin/results/filter/", {
          params: {
            school: filterSchool.value,
            inspection: filterInspection.value,
            status: filterInspectionStatus,
            term: filterInspectionsTerm.value,
            year: filterInspectionYear.value,
          },
        })
        .then(function (response) {
          // Handle the response data here

          let filteredResults = response.data.results.data;
          let tableRows = renderResultsTable(filteredResults);

          tableBody.innerHTML = tableRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  if (filterInspectionsTerm) {
    filterInspectionsTerm.addEventListener("change", function (event) {
      let filterInspectionsTerm = event.target.value;

      let tableBody = document.querySelector("table tbody");
      axios
        .get("/admin/results/filter/", {
          params: {
            school: filterSchool.value,
            inspection: filterInspection.value,
            status: filterInspectionStatus.value,
            term: filterInspectionsTerm,
            year: filterInspectionYear.value,
          },
        })
        .then(function (response) {
          // Handle the response data here

          let filteredResults = response.data.results.data;
          let tableRows = renderResultsTable(filteredResults);

          tableBody.innerHTML = tableRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  if (filterInspectionYear) {
    filterInspectionYear.addEventListener("change", function (event) {
      let filterInspectionYear = event.target.value;

      let tableBody = document.querySelector("table tbody");
      axios
        .get("/admin/results/filter/", {
          params: {
            school: filterSchool.value,
            inspection: filterInspection.value,
            status: filterInspectionStatus.value,
            term: filterInspectionsTerm.value,
            year: filterInspectionYear,
          },
        })
        .then(function (response) {
          // Handle the response data here

          let filteredResults = response.data.results.data;
          let tableRows = renderResultsTable(filteredResults);

          tableBody.innerHTML = tableRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }
}

function renderResultsTable(filteredResults) {
  let tableRows = "";

  filteredResults.forEach(function (results) {
    console.log(results.id);
    tableRows += `
          <tr class="bg-white">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><a href="/admin/results/edit/${
                results.id
              }">View</a></td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${
                results.school_inspection.inspection.name
              }</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${
                results.school_inspection.school.name
              }</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                 ${results.signature_date ? results.signature_date : results.created_at}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${results.status}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${
                results.school_inspection.term.name
              }</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${
                results.school_inspection.term.school_year.name
              }</td>
         
          </tr>
      `;
  });

  return tableRows;
}
export default filterInspections;
