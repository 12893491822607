if (typeof global === "undefined") {
  window.global = window; // Polyfill global if it does not exist
}

import "./bootstrap";
import { applyEdit } from "./results";
import { addRow } from "./results";
import SimpleLightbox from "simplelightbox";

import Alpine from "alpinejs";

import signature_pad from "signature_pad";
import datepicker from "js-datepicker";
import "preline";
import setCurrentSchool from "./currentSchool";
import filterInspection from "./filterInspections";
import filterSchools from "./components/filterSchools";
import cloneHtml from "./components/results/cloneHtml";

document.addEventListener("DOMContentLoaded", () => {
  // Call the setCurrentSchool function
  setCurrentSchool();
  filterInspection();
  filterSchools();
  const lightbox = new SimpleLightbox(".lightbox a", {});
});

window.Alpine = Alpine;

Alpine.start();

const pad = document.getElementById("signature-pad");
let signaturePad;
if (pad) {
  signaturePad = new signature_pad(pad);
}

const saveButton = document.querySelector(".save-signature");
const cancelButton = document.querySelector(".clear-signature");
const signature = document.querySelector("#signature");
const signatureNotification = document.querySelector(".alert");
const tableRowButton = document.querySelector(".add-table-row");
const cloneInspectionButton = document.querySelector(".clone-inspection");
const datepickerElements = document.querySelectorAll(".date"); // Get all elements with class 'date'
const addIssue = document.querySelectorAll(".add-issue");
const modal = document.querySelector("#add-issue-modal");
const modalClose = document.querySelector(".modal-close");

const checkall = document.querySelector(".checkall");
const select = document.querySelector("#tests");
let checked = false;

if (select) {
  checkall.addEventListener("click", function (e) {
    e.preventDefault();
    const options = select.options;

    if (checked == false) {
      for (let i = 0; i < options.length; i++) {
        options[i].selected = true;
        checked = true;
      }
    } else {
      for (let i = 0; i < options.length; i++) {
        options[i].selected = false;
        checked = false;
      }
    }
  });
}

//Results Filtering//

const submitIssue = document.querySelectorAll(".submit-issue");
const saveIssueBtn = document.querySelector("#save-issue");
const deleteIssueBtn = document.querySelector("#delete-issue");

const issueTable = document.querySelector(".issue-table");

const reportDataElement = document.querySelector("#report_data");
const inspectionTable = document.querySelector(".inspection-table");
const modalStatusNotification = document.querySelector(".modal-status");
const markCompleted = document.querySelector(".mark-completed");
const resultStatus = document.querySelector("#result_status");
const signatureEl = document.querySelector(".signature-container");
const issueContainer = document.querySelector(".issue-container");
const statusNotificationMessage = document.querySelector(".status");

if (issueTable) {
  issueTable.addEventListener("change", function (event) {
    //upload issue//
    if (event.target && event.target.classList.contains("upload-issue")) {
      const issueId = event.target.getAttribute("data-issue-id");
      const galleryIssueBtn = document.querySelectorAll(".view-gallery");
      const file = event.target.files[0];

      if (file && issueId) {
        const formData = new FormData();

        togglePreloader(issueId, "show");

        formData.append("issue_id", issueId);
        formData.append("issue_file", file);

        // Use Axios to upload the file
        axios
          .post("/issues/upload/" + issueId, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.data.status === true) {
              //find the clicked button//
              galleryIssueBtn.forEach((btn) => {
                console.log("issue" + issueId);
                if (btn.getAttribute("data-issue-id") === issueId) {
                  btn.classList.remove("hidden");
                }
              });

              statusNotificationMessage.innerHTML = response.data.message;
              statusNotificationMessage.classList.remove("bg-custom-red");
              statusNotificationMessage.classList.add("bg-custom-green");
              togglePreloader(issueId, "hide");
            } else {
              statusNotificationMessage.innerHTML = response.data.message;
              statusNotificationMessage.classList.remove("bg-custom-green");
              statusNotificationMessage.classList.add("bg-custom-red");
              togglePreloader(issueId, "hide");
            }
          })
          .catch(function (error) {
            if (error.response) {
              statusNotificationMessage.innerHTML = error.response.data.message;
              statusNotificationMessage.classList.remove("bg-custom-green"),
                statusNotificationMessage.classList.add("bg-custom-red");
              togglePreloader(issueId, "hide");
            }
          });
      }
    }
  });

  issueTable.addEventListener("click", function (event) {
    //if click on datepicker//
    console.log("TID" + event.target.classList);
    if (event.target.id === "issue_completed_data") {
      handleDatepickerClick(event);
    }

    //delete issue//
    if (event.target.classList.contains("delete-issue")) {
      let issueId = event.target.getAttribute("data-issue-id");

      axios
        .delete("/issues/delete/" + issueId)
        .then(function (response) {
          let responseStatus = response.data.status;

          if (responseStatus === true) {
            statusNotificationMessage.innerHTML = response.data.message;
            statusNotificationMessage.classList.add("bg-custom-green");
            let countIncompleteIssues = response.data.incomplete_issue_count;
            toggleCompleted(countIncompleteIssues);
          } else {
            statusNotificationMessage.innerHTML = response.data.message;
            statusNotificationMessage.classList.add("bg-custom-red");
          }
          event.target.closest("tr").remove();
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    //view  gallery//
    if (event.target.classList.contains("view-gallery")) {
      let issueId = event.target.getAttribute("data-issue-id");
      const galleryModal = document.querySelector("#gallery-modal");
      let galleryModalContent = galleryModal.querySelector(".content");
      const galleryModalClose = galleryModal.querySelector(".modal-close");
      let html = "";
      axios
        .get("/issues/gallery/" + issueId)
        .then(function (response) {
          Object.keys(response.data).forEach(function (key) {
            html += `<a href="${response.data[key].original_url}" data-lightbox="${response.data[key].original_url}"><img src="${response.data[key].thumbnail_url}" alt="issue image" class="w-80 h-40"></a>`;
          });

          galleryModalContent.innerHTML = html;
          console.log(galleryModalContent);
          galleryModal.style.display = "flex";
          lightbox = new SimpleLightbox(".lightbox a", {
            /* options */
          });
          lightbox.refresh();
        })

        .catch(function (error) {
          console.log(error);
        });

      galleryModalClose.addEventListener("click", function (event) {
        galleryModal.style.display = "none";
      });
    }

    //edit issue//
    if (event.target.classList.contains("save-issue")) {
      let issueId = event.target.getAttribute("data-issue-id");
      let issueEl = event.target.closest("tr");

      let status = issueEl.querySelector("#issue_status").value;
      let description = issueEl.querySelector("#issue_description").value;
      let action = issueEl.querySelector("#issue_action").value;
      let completedDate = issueEl.querySelector("#issue_completed_data").value;

      axios
        .put("/issues/edit/" + issueId, {
          issue: issueId,
          status: status,
          description: description,
          action: action,
          completedDate: completedDate,
        })
        .then(function (response) {
          console.log(response);
          let responseStatus = response.data.status;

          let countIncompleteIssues = response.data.incomplete_issue_count;
          toggleCompleted(countIncompleteIssues);

          if (responseStatus === true) {
            statusNotificationMessage.innerHTML = response.data.message;
            statusNotificationMessage.classList.add("bg-custom-green");
          } else {
            statusNotificationMessage.innerHTML = response.data.message;
            statusNotificationMessage.classList.add("bg-custom-red");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  });
}

if (resultStatus) {
  resultStatus.addEventListener("change", function (event) {
    console.log("changed");
    if (resultStatus.checked) {
      alert(
        "If you are marking the inspection as complete, please sign the inspection and mark the inspection is completed.",
      );
      signatureEl.classList.remove("hidden");
    } else {
      signatureEl.classList.add("hidden");
    }
  });
}

if (modalClose) {
  modalClose.addEventListener("click", function (event) {
    modal.classList.add("hidden");
    event.preventDefault();

    modalStatusNotification.classList.add("hidden");
  });
}

if (submitIssue) {
  submitIssue.forEach(function (element) {
    element.addEventListener("click", function (event) {
      event.preventDefault();

      let result = document.querySelector("#result_id");
      let test = document.querySelector("#test_id").value;
      let description = document.querySelector("#description");
      let reportData = document.querySelectorAll(".report_data");
      let reportId = document.querySelector("#report_id");

      let issueEl = document.querySelector(".issue-wrapper");
      let issueTable = document.querySelector(".issue-table tbody");

      if (description.value != "") {
        axios
          .post("/issues/create", {
            result: result.value,
            test: test,
            description: description.value,
            reportData: reportData[0].value,
          })
          .then(function (response) {
            let status = response.data.status;

            if (status === true) {
              modalStatusNotification.innerHTML = response.data.message;
              modalStatusNotification.classList.add("bg-custom-green");

              modalStatusNotification.classList.remove("hidden", "bg-custom-red");

              result.value = response.data.result_id;
              reportId.value = response.data.result_id;

              description.value = "";
              let filteredResults = response.data;

              let tableRows = renderIssueTable(filteredResults);
              let countIncompleteIssues = filteredResults.incomplete_issue_count;
              toggleCompleted(countIncompleteIssues);

              issueTable.innerHTML += tableRows;
            } else {
              modalStatusNotification.innerHTML = response.data.message;
              modalStatusNotification.classList.add("bg-custom-red");
            }

            // issueNotification.classList.remove("hidden");
            //  issueNotificationMessage.innerHTML = response.data.message;
            //issueDescription.value = "";
          })
          .catch(function (error) {
            console.log(error);
            //issueNotification.classList.remove("hidden");
            // issueNotificationMessage.innerHTML = error;
          });
      } else {
        modalStatusNotification.innerHTML = "Please enter a description";
        modalStatusNotification.classList.add("bg-custom-red");
        modalStatusNotification.classList.remove("hidden");
      }
    });
  });
}

if (inspectionTable) {
  inspectionTable.addEventListener("click", function (event) {
    const clickedElement = event.target;
    if (event.target.classList.contains("add-issue")) {
      let options = clickedElement.options;
      console.log(options);

      //remove selected options//
      for (var i = 0; i < options.length; i++) {
        options[i].removeAttribute("selected");
      }

      clickedElement.addEventListener("change", function () {
        // Your logic when an element with the "add-issue" class changes

        const selectedOption = clickedElement.querySelector(
          `option[value="${event.target.value}"]`,
        );

        if (selectedOption) {
          selectedOption.setAttribute("selected", "selected");
        }

        if (event.target.value === "Yes") {
          modal.classList.remove("hidden");

          // Triggering the modal from your main application logic
        }
        reportDataElement.value = inspectionTable.outerHTML;
      });
    }

    if (event.target.classList.contains("yes-no")) {
      console.log("yes no");
      reportDataElement.value = inspectionTable.outerHTML;
      let options = clickedElement.options;
      console.log(options);

      //remove selected options//
      for (var i = 0; i < options.length; i++) {
        options[i].removeAttribute("selected");
      }

      clickedElement.addEventListener("change", function () {
        // Your logic when an element with the "add-issue" class changes

        const selectedOption = clickedElement.querySelector(
          `option[value="${event.target.value}"]`,
        );

        if (selectedOption) {
          selectedOption.setAttribute("selected", "selected");
        }

        reportDataElement.value = inspectionTable.outerHTML;
      });
    }
  });
}

const togglePreloader = (issueId, action) => {
  const preloader = document.querySelector(`.preloader[data-issue-id="${issueId}"]`);
  console.log(preloader);
  if (preloader) {
    if (action === "show") {
      preloader.classList.remove("hidden");
    } else if (action === "hide") {
      preloader.classList.add("hidden");
    }
  }
};

const handleDatepickerClick = (event) => {
  console.log("datepicker clicked");
  let datepicker = event.target;
  let currentDate = new Date().toISOString().split("T")[0];
  console.log(currentDate);

  datepicker.max = currentDate;
};

const renderIssueTable = (filteredResults) => {
  let tableRows = "";

  tableRows += `
    <tr class="border-b dark:border-neutral-500">
    <td class="whitespace-nowrap p-2 block w-full lg:table-cell lg:w-auto">
        <h3 class="font-medium font-bold text-gray-900 lg:hidden">Description</h3>
        <textarea name="issue_description" id="issue_description" class="w-full border border-gray-300 resize-none">${filteredResults.issue_description}</textarea>
    </td>

    <td class="whitespace-nowrap p-2 block w-full lg:table-cell lg:w-auto">
      <h3 class="font-medium font-bold text-gray-900 lg:hidden">Action Taken</h3>
        <textarea name="issue_action" id="issue_action" class="w-full border border-gray-300 resize-none">${filteredResults.issue_actions}</textarea>
    </td>

    
    <td class="whitespace-nowrap p-2 block w-full lg:table-cell lg:w-auto">
    <h3 class="font-medium font-boldtext-gray-900 lg:hidden">Action Date</h3>
    <input type="date" name="issue_completed_data" id="issue_completed_data" class="border-gray-300" value="${filteredResults.completedDate}">
    </td>

    
    <td class="whitespace-nowrap p-2 block w-full lg:table-cell lg:w-auto">
    <h3 class="font-medium font-boldtext-gray-900 lg:hidden">Status</h3>
        <select name="issue_status" id="issue_status" class="w-full border border-gray-300">
            <option value="incomplete">Incomplete</option>
            <option value="complete">Complete</option>
        </select>
    </td>


    <td class="whitespace-nowrap p-2 block w-full lg:table-cell lg:w-auto">
    <h3 class="font-medium font-boldtext-gray-900 lg:hidden">Uploads</h3>
      <input type="file" name="upload-issue" data-issue-id="${filteredResults.issue_id}"  class="upload-issue w-full border border-gray-300">
    </td>





    <td class="whitespace-nowrap p-2 block w-full lg:w-auto lg:table-cell flex">
      <div class="flex sm:flex-wrap lg:flex-nowrap">

               <div class="preloader hidden" data-issue-id="${filteredResults.issue_id}">
                                                <div role="status">
                                                    <svg aria-hidden="true" class="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                Uploading your file
       </div>

        <button class="bg-custom-purple hover:bg-custom-purple-light text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline view-gallery hidden ml-1 mb-0 sm:mb-1 lg:mb-0" data-issue-id="${filteredResults.issue_id}">View Photos</button>
        <button class="bg-custom-purple-light hover:bg-custom-purple text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline delete-issue  ml-1 mb-0 sm:mb-1 lg:mb-0" data-issue-id=${filteredResults.issue_id}>Delete</button>
        <button class="bg-custom-purple hover:bg-custom-purple-light text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline save-issue  ml-1" data-issue-id="${filteredResults.issue_id}">Save</button>
      </div>
        </form>
    </td>

</tr>
      `;

  return tableRows;
};

const toggleCompleted = (issueCount) => {
  if (issueCount > 0) {
    markCompleted.classList.add("hidden");
    issueContainer.classList.remove("hidden");
  } else {
    markCompleted.classList.remove("hidden");
  }
};

if (cancelButton) {
  cancelButton.addEventListener("click", function (event) {
    signaturePad.clear();
    signature.value = "";
    signatureNotification.classList.add("hidden");
    event.preventDefault();
  });
}

if (saveButton) {
  saveButton.addEventListener("click", function (event) {
    if (!signaturePad.isEmpty()) {
      const data = signaturePad.toDataURL("image/png");
      console.log(data);

      axios
        .post("/results/savesig", {
          data: data,
        })

        .then(function (response) {
          signature.value = response.data.filename;
          signatureNotification.classList.remove("hidden");
          signatureNotification.querySelector("span").innerHTML = response.data.message;
        })
        .catch(function (error) {
          signatureNotification.classList.remove("hidden");
          signatureNotification.querySelector("span").innerHTML = error;
        });
    }
    event.preventDefault();
  });
}

const today = new Date();

datepickerElements.forEach(function (element) {
  //  const picker = datepicker(element, { startDate: today, date: today });
});
//const picker = datepicker('.date',{ startDate: today, date: today });

//*fixed play add new item//
const newItemButton = document.querySelector(".add-new-item");
if (newItemButton) {
  newItemButton.addEventListener("click", function (event) {
    let inspectionName = event.target.getAttribute("data-inspection-name");
    const config = inspectionConfigs[inspectionName];
    if (config) {
      cloneHtml([0, 3]);
      applyEdit(config.selector, config.columns, config.types, ".report_data");
    }
  });
}
/*inspection stuff*/
///initalise inspection configs///
import { inspectionConfigs } from "./configs";
for (const inspectionName in inspectionConfigs) {
  if (inspectionConfigs.hasOwnProperty(inspectionName)) {
    const { selector, columns, types } = inspectionConfigs[inspectionName];
    applyEdit(selector, columns, types, ".report_data");
  }
} ///initalise inspection configs///

//if clicked add correct config//
if (tableRowButton) {
  tableRowButton.addEventListener("click", function (event) {
    const inspectionName = tableRowButton.getAttribute("data-inspection-name");

    const config = inspectionConfigs[inspectionName];
    console.log(config.types);
    console.log(config.selector);
    console.log(config);
    if (config) {
      addRow(config.selector, config.types, ".report-data");
    }
  });
}
//if clicked add correct config//

//clone inspection and apply correct config//
if (cloneInspectionButton) {
  cloneInspectionButton.addEventListener("click", function (event) {
    const inspectionID = cloneInspectionButton.getAttribute("data-inspection-id");
    const inspectionName = cloneInspectionButton.getAttribute("data-inspection-name");
    const notifications = document.querySelector(".messages");
    axios
      .get("/results/clone/" + inspectionID)
      .then(function (response) {
        let inspection = response.data;
        notifications.innerHTML = inspection.message;
        if (inspection.status === true) {
          const cloneData = document.querySelector(".clone-data");
          const inspectionField = document.querySelector("#report_data");

          let inspectionTableStripped = removeSelectedIssueOptions(inspection.inspection_data);
          cloneData.innerHTML = inspectionTableStripped;

          inspectionField.value = inspection.inspection_data;
          notifications.classList.add("p-4", "text-white", "bg-custom-green", "sm:rounded-lg");
          const config = inspectionConfigs[inspectionName];

          if (config) {
            applyEdit(config.selector, config.columns, config.types, ".report_data");
          }
        } else {
          notifications.classList.add("p-4", "text-white", "bg-custom-red", "sm:rounded-lg");
        }
      })

      .catch(function (error) {
        console.log(error);
      });
  });
}
//clone inspection and apply correct config//

//remove selected options from issue when cloning//
function removeSelectedIssueOptions(htmlString) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Select all <select> elements with the class 'add-issue'
  const selects = tempDiv.querySelectorAll("select.add-issue");

  // Iterate over each <select> element
  selects.forEach((select) => {
    // Select all <option> elements within this <select>
    const options = select.querySelectorAll("option[selected]");

    // Remove the 'selected' attribute from each <option>
    options.forEach((option) => {
      option.removeAttribute("selected");
    });
  });

  console.log("div" + tempDiv.innerHTML);
  // Return the updated HTML string
  return tempDiv.innerHTML;
}
