export const inspectionConfigs = {
  "checks of school site": {
    selector: ".school-checks",
    columns: [0, 1, 2],
    types: ["textarea", "textarea", "select"],
  },
  "fire alarm testing": {
    selector: ".firealarm-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "defib checks": {
    selector: ".defib-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "green box testing": {
    selector: ".greenbox-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "fire extinguisher checks": {
    selector: ".fireextinguisher-checks",
    columns: [0, 1, 2],
    types: ["textarea", "textarea", "select"],
  },
  "water dispensers cleaned": {
    selector: ".waterdispenser-checks",
    columns: [0, 1, 2],
    types: ["textarea", "textarea", "select"],
  },
  "vehicle checks (minibus)": {
    selector: ".vehicle-checks",
    columns: [0, 1, 2],
    types: ["textarea", "textarea", "select"],
  },
  "fire doors, entrances": {
    selector: ".firedoor-entrances-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "water flushing outlets": {
    selector: ".waterflushing-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "water sprinkler tank": {
    selector: ".watersprinkler-checks",
    columns: [0, 1],
    types: ["textarea", "textarea", "select"],
  },
  "emergency lighting testing": {
    selector: ".lighting-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "emergency pull cord testing": {
    selector: ".pullcord-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "temperature checks": {
    selector: ".temperature-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "descaling of shower heads": {
    selector: ".showerheads-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "fire drills": {
    selector: ".firedrill-checks",
    columns: [0, 1],
    types: ["textarea", "textarea", "select"],
  },
  "folding tables": {
    selector: ".foldtables-checks",
    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    types: [
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "select",
    ],
  },
  "rcds testing": {
    selector: ".rcd-checks",
    columns: [0, 1],
    types: ["textarea", "select"],
  },
  "ladder inspections": {
    selector: ".ladder-checks",
    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    types: ["textarea", "yesno", "yesno", "yesno", "yesno", "yesno", "yesno", "yesno", "select"],
  },
  "kickstool inspections": {
    selector: ".kickstool-checks",
    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    types: ["textarea", "yesno", "yesno", "yesno", "yesno", "yesno", "yesno", "yesno", "select"],
  },
  "tree management checks": {
    selector: ".tree-monitoring-checks",
    columns: [0, 1, 2, 3, 4, 5, 6, 7],
    types: [
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "textarea",
      "select",
    ],
  },
  "finger traps": {
    selector: ".fingertrap-checks",
    columns: [0, 1, 2],
    types: ["yesno", "textarea", "select"],
  },

  "av screens": {
    selector: ".avscreens-checks",
    columns: [0, 1, 2],
    types: ["textarea", "yesno", "select"],
  },
  "fixed play": {
    selector: ".fixed-wrapper",
    columns: [0, 1, 2, 3, 4],
    types: ["textarea", "textarea", "textarea", "yesno", "select"],
  },
};
