const cloneHtml = (columnsToClear) => {
  const table = document.getElementById("table1");
  const clone = table.cloneNode(true);

  // Remove content between <td></td> in the specified columns of the cloned table
  const rows = clone.querySelectorAll("tbody tr");
  rows.forEach((row) => {
    columnsToClear.forEach((colIndex) => {
      const cell = row.children[colIndex];
      if (cell) {
        cell.innerHTML = ""; // Clear the content
      }
    });
  });

  // Create a remove button
  const removeButton = document.createElement("button");
  removeButton.textContent = "Remove Item";
  removeButton.classList.add(
    "bg-custom-purple",
    "hover:bg-custom-purple",
    "text-white",
    "font-bold",
    "py-3",
    "px-6",
    "rounded",
    "focus:outline-none",
    "focus:shadow-outline",
    "my-8",
    "absolute",
    "right-0",
    "bottom-0",
    "transition",
    "my-8",
    "remove-item-button",
  );
  removeButton.addEventListener("click", () => {
    clone.remove();
    removeButton.remove();
  });

  // Append the remove button to the cloned table
  const wrapper = document.createElement("div");
  wrapper.classList.add("relative", "py-8", "my-12");
  wrapper.appendChild(clone);
  wrapper.appendChild(removeButton);

  document.querySelector(".fixed-wrapper").appendChild(wrapper);
};

const removeButton = document.querySelectorAll(".remove-item-button");
if (removeButton) {
  removeButton.forEach((button) => {
    button.addEventListener("click", (event) => {
      button.closest(".relative").remove();
    });
  });
}

export default cloneHtml;
