// Debounce function
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}

function filterSchools() {
  const filter = document.getElementById("filterSchool");
  const results = document.querySelector(".results ul");
  const resultsContainer = document.querySelector(".results");

  // Event handler for keyup with debouncing
  const handleKeyup = debounce(function (event) {
    results.innerHTML = "";
    let filterSchool = event.target.value;
    console.log(filterSchool);
    axios
      .get("/admin/schools/search/", {
        params: {
          school: filterSchool,
        },
      })
      .then(function (response) {
        // Handle the response data here
        let filteredResults = response.data;
        results.classList.remove("hidden");

        if (filteredResults.length === 0) {
          results.innerHTML = `<li class="p-4">No results found</li>`;
          return;
        } else {
          filteredResults.forEach((result) => {
            results.innerHTML += `<li class="p-4"><a href="/admin/schools/${result.id}/edit/">${result.name}</a></li>`;
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, 300); // Adjust the delay as needed (300 milliseconds in this example)

  if (!filter) return;
  filter.addEventListener("keyup", handleKeyup);

  // Event listener to hide results when clicking outside
  document.addEventListener("click", function (event) {
    if (!filter.contains(event.target) && !resultsContainer.contains(event.target)) {
      results.innerHTML = "";
    }
  });
}

export default filterSchools;
