import datepicker from "js-datepicker";

const getStyle = (el, cssprop) => {
  if (el.currentStyle) return el.currentStyle[cssprop]; // IE
  else if (document.defaultView && document.defaultView.getComputedStyle)
    return document.defaultView.getComputedStyle(el, "")[cssprop]; // Firefox
  else return el.style[cssprop]; // Try and get inline style
};

export function applyEdit(tabID, editables, inputs, textID) {
  const tab = document.querySelector(tabID);
  const textarea = document.querySelectorAll(textID);
  const createField = inputs;

  if (tab) {
    const rows = tab.getElementsByTagName("tr");
    for (let r = 0; r < rows.length; r++) {
      const tds = rows[r].getElementsByTagName("td");
      for (let c = 0; c < tds.length; c++) {
        if (editables.includes(c))
          tds[c].ondblclick = (e) => {
            e.stopPropagation();
            beginEdit(tds[c], tab, createField[c], textarea);
          };
      }
    }
  }
}

let oldColor,
  oldText,
  padTop,
  padBottom = "";

const beginEdit = (td, tab, createField, textarea) => {
  if (td.firstChild && td.firstChild.tagName === "INPUT") return;

  oldText = td.innerHTML.trim();
  oldColor = getStyle(td, "backgroundColor");
  padTop = getStyle(td, "paddingTop");
  padBottom = getStyle(td, "paddingBottom");

  let input;

  if (createField === "input") {
    input = document.createElement("input");
    input.value = oldText;
    input.type = "date";
    td.insertBefore(input, td.firstChild);

    input.select();
    input.onblur = () => endEdit(input, tab, textarea);
  } else if (createField === "select") {
    if (checkFieldExists(td) === false) {
      input = document.createElement("select");
      input.innerHTML = ``;
      input.innerHTML += `<option value="No">No</option>`;
      input.innerHTML += `<option value="Yes">Yes</option>`;
      input.classList.add("add-issue");
      td.insertBefore(input, td.firstChild);

      endEdit(input, tab, textarea);
    } else {
      console.log("field exists");
    }
  } else if (createField === "yesno") {
    if (checkFieldExists(td) === false) {
      console.log("hit");
      input = document.createElement("select");
      input.innerHTML = ``;
      input.innerHTML += `<option value="No">No</option>`;
      input.innerHTML += `<option value="Yes">Yes</option>`;
      input.classList.add("yes-no");

      td.insertBefore(input, td.firstChild);
      endEdit(input, tab, textarea);
    } else {
      console.log("field exists");
    }
  } else {
    input = document.createElement("input");
    input.value = oldText;

    input.classList.add("w-full");
    input.classList.add("h-40");
    td.insertBefore(input, td.firstChild);

    input.select();
    input.onblur = () => endEdit(input, tab, textarea);
  }
};

const checkFieldExists = (td) => {
  if (td.firstChild && td.firstChild.tagName === "SELECT") {
    return true;
  } else {
    return false;
  }
};
const endEdit = (input, tab, textarea) => {
  console.log("end edit");
  const td = input.parentNode;
  td.removeChild(td.firstChild); // Remove input
  td.innerHTML = input.value;

  // Check if input is an HTMLSelectElement
  if (input instanceof HTMLSelectElement) {
    // Do something when input is a select element
    td.innerHTML = input.outerHTML;
  } else {
    td.innerHTML = input.value;
  }

  if (textarea) {
    textarea.forEach(function (element) {
      element.value = tab.outerHTML;
      console.log(element.value);
    });
  } else {
    const fixText = document.querySelector("body");
    const reportDataTextarea = fixText.querySelector("#report_data");
    console.log(reportDataTextarea);
    reportDataTextarea.value = tab.outerHTML;
    console.log(reportDataTextarea.value);
    /* fixText.forEach(function (element) {
      element.value = tab.i;
    });*/
  }
};

export function addRow(tab, inputs, inputField) {
  const table = document.querySelector(tab);

  const input = document.querySelector(inputField);
  const createField = inputs;
  const rows = table.getElementsByTagName("tr");
  const newRow = rows[rows.length - 1].cloneNode(true);
  const tds = newRow.getElementsByTagName("td");

  for (let c = 0; c < tds.length; c++) {
    tds[c].innerHTML = "";
    tds[c].ondblclick = () => beginEdit(tds[c], table, createField[c], input);
  }
  table.appendChild(newRow);
}
