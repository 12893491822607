function setCurrentSchool() {
  let CurrentSchool = document.querySelector(".currentSchool");
  let notifications = document.querySelector(".notifications");

  if (CurrentSchool) {
    CurrentSchool.addEventListener("change", function (event) {
      let school = CurrentSchool.value;
      event.preventDefault();
      console.log(school);
      axios
        .post("/setcurrentschool", {
          school: school,
        })
        .then(function (response) {
          console.log(response);
          notifications.innerHTML = '<div class="alert alert-success" role="alert"> </div>';

          notifications.innerHTML = response.data.message;
          notifications.classList.add("bg-custom-green");
          location.reload(4);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }
}
export default setCurrentSchool;
